<template>
    <div class="col-lg-7 col-xl-4">
        <!-- Simple card -->
        <b-card style="border-radius: 13px;">
            <!-- <router-link :to="'/projects/channelDetails/' + id" style=" color: #303030;"> -->
                <div>
                    <img class="card-img" :src="image" alt="Card image" style="object-fit: cover; opacity: 0.9; ">
                    <div style="
                    top: 20px;
                    height: 200px;
                    width: 90%;
                    position: absolute;
                    border-radius: 10px;
                    z-index: 1;
                    -webkit-box-shadow : inset 0px -185px 112px -95px rgba(4,87,17,0.57);
                    -moz-box-shadow : inset 0px -185px 112px -95px rgba(4,87,17,0.57);
                    box-shadow : inset 0px -185px 112px -95px rgba (4,87,17,0.57);
                    "></div>
                    <h4 style="position:absolute; top:194px; left: 30px; color: #fff; z-index: 11;">{{ time }}</h4>
                </div>

                <b-card-title>
                    <h5 class="card-title"
                        style=" font-size: 1.4em; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 20px;">
                        {{ title }}</h5>
                </b-card-title>
                <b-card-text
                    style="font-size: 1.2em; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; line-clamp: 2; -webkit-box-orient: vertical; margin-bottom: 20px;">
                    <div>
                        {{ description }}
                    </div>
                </b-card-text>
            <!-- </router-link> -->
            <b-button class="c2play-primary" style="margin-left: 42%; margin-right: auto;">
                <b-dropdown class="card-drop" variant="white" menu-class="dropdown-menu-end" right toggle-class="p-0">
                    <template v-slot:button-content>
                        <span style="color: #fff;"><i class="mdi mdi-dots-horizontal font-size-18"></i></span>
                    </template>
                    <b-dropdown-item @click="showUpdateModal">{{ $t("pages.videos.actions.modifier") }}</b-dropdown-item>
                    <b-dropdown-item @click="confirm">{{ $t("pages.videos.actions.supprimer") }}</b-dropdown-item>
                </b-dropdown>
            </b-button>
        </b-card>
    </div>

    <b-modal v-model="updateModal" id="modal-xl" size="xl" :title="$t('pages.updateChannel.title')" title-class="font-18" hide-footer>

        <div class="card-body">
            <form>
                <div class="row">
                    <div class="col-lg-6">
                        <label class=" col-form-label">{{ $t('pages.updateChannel.formulaire.name.label') }}</label>
                        <div class="col-md-12">
                            <input id="projectname" name="projectname" type="text" class="form-control"
                                v-model="channel.titre"
                                :placeholder="$t('pages.updateChannel.formulaire.name.placeholder')" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="projectname" class="col-form-label">{{
                                $t('pages.updateChannel.formulaire.link.label') }}</label>
                            <div class="col-lg-12">
                                <input id="projectname" name="projectname" type="text" class="form-control"
                                    :placeholder="$t('pages.updateChannel.formulaire.link.placeholder')"
                                    v-model="channel.url" />
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div class="row mb3">
                    <div class="col-lg-6">
                        <label class=" col-form-label">{{ $t('pages.updateChannel.formulaire.logo') }}</label>
                        <div class="col-md-12">
                            <!-- {{ imageName }} -->
                            <input id="projectname" name="projectname" type="file" class="form-control"
                                @change="logoChannel($event)" placeholder="Entre le titre de la vidéo..."
                                accept=".png, .jpeg, .jpg" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <label class=" col-form-label">Live par défaut</label>
                        <div class="col-md-12">
                            <!-- {{ imageName }} -->
                            <input id="projectname" name="projectname" type="file" class="form-control"
                                @change="liveChannel($event)" placeholder="Entre le titre de la vidéo..."
                                accept=".png, .jpeg, .jpg" />
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="projectdesc" class="col-form-label">{{
                        $t('pages.updateChannel.formulaire.description.label') }}</label>
                    <div class="col-lg-12">
                        <textarea id="projectdesc" class="form-control" rows="3"
                            :placeholder="$t('pages.updateChannel.formulaire.description.placeholder')"
                            v-model="channel.description"></textarea>
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="col-lg-10">
                    <button type="submit" class="btn c2play-primary" @click="updateChannel">
                        {{ $t('updateButton') }}
                    </button>
                </div>
            </div>
        </div>
    </b-modal>
</template>
  
<script>
import Swal from 'sweetalert2';
import { Api } from '../../helpers';
import { Erreur } from '../../helpers/error';
export default {
    data() {
        return {
            updateModal: false,
            channel: {
                titre: "",
                image: "",
                url: "",
                description: "",
            }
        }
    },
    props: {
        id: {
            default: ""
        },
        image: {
            default: ""
        },
        imageName: {
            default: ""
        },
        title: {
            default: ""
        },
        time: {
            default: ""
        },
        description: {
            default: ""
        },
        url: {
            default: ""
        },
        route1: {
            default: ""
        },
        route2: {
            default: ""
        },
        route3: {
            default: ""
        }
    },
    methods: {
        logoChannel(event) {
            this.imagePSelectFile = event.target.files[0]
            console.log(event)
        },
        liveChannel(event) {
            this.imgLiveChannel = event.target.files[0]
            console.log(event)
        },
        updateChannel() {
            this.process = true

            Api.putFormData("/streamvod/rest/channel/update/" + this.id + "?channel_description=" + this.channel.description + "&channel_name=" + this.channel.titre + "&channel_url=" + this.channel.url, {
                file: this.imagePSelectFile,
                file_live: this.imgLiveChannel
            })
                .then((response) => {
                    this.process = false
                    console.log(response)
                    location.reload()
                }).catch((error) => {
                    this.process = false
                    console.log(error)
                    Erreur.gestionErreur(error.message)
                })
        },
        showUpdateModal() {
            this.updateModal = true
        },

        confirm() {
            Swal.fire({
                title: "Etes vous sùre?",
                // text: "You won't be able to delete this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                cancelButtonText: "Annuler",
                confirmButtonText: "Supprimer",
            }).then((result) => {
                if (result.value) {
                    this.deleteChannel();
                    // location.reload();
                }
            });
        },
        deleteChannel() {
            Api.delete("/streamvod/rest/channel/delete/" + this.id)
                .then((response) => {
                    this.channels = response.data.content
                    console.log(this.channels)
                    Swal.fire("Deleted!", "Event has been deleted.", "success");
                    location.reload()
                }).catch((error) => {
                    this.process = false
                    console.log(error)
                    Erreur.gestionErreur(error.message)
                })
        },
    },
    mounted() {
        this.channel.titre = this.title
        this.channel.description = this.description
        this.channel.url = this.url
        this.channel.image = this.image
    }
}
</script>
  
<style>
.card-img {
    object-fit: cover;
    height: 200px;
    width: 100%;
    border-radius: 9px;
    margin-bottom: 20px;
}
</style>