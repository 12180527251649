<script>
var moment = require('moment');
moment.locale('fr');
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Swal from "sweetalert2";
import { Api } from '../../../helpers';
import { Erreur } from "../../../helpers/error";
import channelCard from "../../../components/widgets/channelCard.vue";
import loaderProcessVue from '../../../components/widgets/loaderProcess.vue';
/**
 * Projects-list component
 */
export default {
  page: {
    title: "Projects List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    channelCard,
    loaderProcessVue,
  },

  data() {
    return {
      modalOk: false,
      progress:null,
      titre:"",
      channels: [],
      moment: moment,
      nom: "",
      description: "",
      url: "",
      title: "Liste des chaînes",
      items: [
        {
          text: "Gestion des chaines",
          // href: "/projects/channels",
        },
        {
          text: "Chaines",
          active: true,
        },
      ],
    };
  },
  methods: {
    showModal() {
      this.modalOk = true
    },
    logoChannel(event) {
      this.imagePSelectFile = event.target.files[0]
    },
    liveImage(event) {
      this.imageLive = event.target.files[0]
    },
    createChannel() {
      this.process = true
      this.modalOk = false
      this.titre = "Création de chaine"
      const onUploadProgress = (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          this.progress = Math.min(progress, 98); // Limite la valeur maximale à 98
        };

      Api.postFormData("/streamvod/rest/channel/create-channel?description=" + this.description, {
        file: this.imagePSelectFile,
        file_live: this.imageLive,
        name: this.nom,
        url: this.url
      },onUploadProgress).then(() => {
        this.process = false
        Swal.fire("Succes!", "Chaine créée", "Success");
        location.reload()
      }).catch((error) => {
        this.process = false
        Erreur.gestionErreur(error.message)
      })
    },



    confirm(id) {
      Swal.fire({
        title: "Etes vous sùre?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Supprimer",
      }).then((result) => {
        if (result.value) {
          this.deleteChannel(id);
          Swal.fire("Deleted!", "Event has been deleted.", "success");
          location.reload();
        }
      });
    },
    deleteChannel(Channelid) {

      Api.delete("/streamvod/rest/channel/delete/" + Channelid)
        .then((response) => {
          this.channels = response.data.content
          location.reload()
        }).catch((error) => {
          this.process = false
          Erreur.gestionErreur(error.message)
        })
    },
  },

  mounted() {
    Api.get("/streamvod/api/channel/channel-all")
      .then((response) => {
        this.channels = response.data.content
      }).catch((error) => {
        this.process = false
        Erreur.gestionErreur(error.message)
      })
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loaderProcessVue :visible="process" :progress="progress" :title="titre" />


    <div class="row justify-content-end">
      <div class="col-lg-10" style="margin-top: 10px;">
        <button type="submit" class="btn c2play-primary" style="position: relative; left: 90%;" @click="showModal">
          {{ $t('addButton') }}
        </button>
      </div>
    </div>
    <br>

    <div class="row">
      <channelCard v-for="(channel, index) in channels" :key="index" :image="channel.channel_logo.url"
        :title="channel.channel_name" :description="channel.channel_description" :url="channel.channel_url" :id="channel.id"
        :imageName="channel.channel_logo.name"></channelCard>
      <!-- end col -->
    </div>
    <!-- end row -->

    <!-- <div class="row">
        <div class="col-12">
          <div class="text-center my-3">
            <a href="javascript:void(0);" class="text-success">
              <i class="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
              Load more
            </a>
          </div>
        </div>
      </div> -->


    <b-modal v-model="modalOk" id="modal-xl" size="xl" :title="$t('pages.channels.formulaire.title')"
      title-class="font-18" hide-footer>
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-lg-6">
              <label class=" col-form-label">{{ $t('pages.channels.formulaire.name.label') }}</label>
              <div class="col-md-12">
                <input id="projectname" name="projectname" type="text" class="form-control" v-model="nom"
                  :placeholder="$t('pages.channels.formulaire.name.placeholder')" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="projectname" class="col-form-label">{{ $t('pages.channels.formulaire.link.label') }}</label>
                <div class="col-lg-12">
                  <input id="projectname" name="projectname" type="text" class="form-control"
                    :placeholder="$t('pages.channels.formulaire.link.placeholder')" v-model="url" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <label class=" col-form-label">{{ $t('pages.channels.formulaire.logo') }}</label>
              <div class="col-md-12">
                <input id="projectname" name="projectname" type="file" class="form-control" @change="logoChannel($event)"
                  placeholder="Entre le titre de la vidéo..." accept=".png, .jpeg, .jpg" />
              </div>
            </div>
            <div class="col-lg-6">
              <label class=" col-form-label">Image Live</label>
              <div class="col-md-12">
                <input id="projectname" name="projectname" type="file" class="form-control" @change="liveImage($event)"
                  placeholder="Entre le titre de la vidéo..." accept=".png, .jpeg, .jpg" />
              </div>
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="projectdesc" class="col-form-label">{{ $t('pages.channels.formulaire.description.label')
            }}</label>
            <div class="col-lg-12">
              <textarea id="projectdesc" class="form-control" rows="3"
                :placeholder="$t('pages.channels.formulaire.description.placeholder')" v-model="description"></textarea>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-lg-10">
            <button type="submit" class="btn c2play-primary" @click="createChannel">
              {{ $t('addButton') }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end row -->
    <!-- <loaderProcessVue v-if="process = true"></loaderProcessVue> -->
  </Layout>
</template>

<style></style>